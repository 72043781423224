<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-estado">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Estado 1</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="form_estado.estado"
                :class="
                  $v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in $parent.listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.form_estado.estado.required">
                Seleccione un estado
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "VehiculoEstado",
  data() {
    return {
      form_estado: {},
    };
  },
  validations: {
    form_estado: {
      estado: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
      entidad_id: {
        required,
      },
      entidad: {
        required,
      },
    },
  },
  methods: {
    save() {
      if (!this.$v.form_estado.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/vehiculos",
          data: this.form_estado,
        })
          .then((response) => {
            this.form_estado = {};
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se realizo el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.form_estado = {};
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },

    llenar_modal(id, valor_ant) {
      this.form_estado.entidad = "Vehiculos";
      this.form_estado.entidad_id = id;
      this.form_estado.id = id;
      this.form_estado.valor_ant = valor_ant;
      this.form_estado.user = this.$store.state.user.id;
    },
  },
};
</script>
